<template>
  <EdgeStack
    :id="esId"
    :busy="busy"
    :exitButton="{ text: 'Cancel', hidden: false }"
    :size-class="`w-full md:w-11/12 fixed`"
  >
    <template #header>
      <div class="flex justify-between -mt-8">
        <div class="panel-title">{{ actionText }} Template</div>
      </div>
    </template>

    <template #footer>
      <AppButton
        variant="primary"
        :text="actionText"
        :is-loading="isReq"
        @click="$refs.submitButton.click()"
      />
    </template>

    <template #default>
      <ValidationObserver
        tag="div"
        class="px-4"
        v-slot="{ handleSubmit }"
        ref="observer"
      >
        <div class="flex gap-4 flex-col md:flex-row">
          <div class="w-full md:w-60 flex flex-col">
            <AppInput
              type="text"
              name="template_type"
              label="Type"
              rules="required"
              placeholder="Type"
              :disabled="true"
              v-model="form.template.template_type"
            />

            <TemplateDetailsLanguageDropdown
              class="mb-6"
              v-model="form.language"
              :label="'Language'"
              :options="languages"
            />
            <AppInput
              type="richselect"
              name="fleet"
              label="Fleet"
              placeholder="Select a fleet"
              :options="fleets"
              hide-search-box
              v-model="form.fleet"
            />
            <div>
              <AppFormLabel text="Placeholder" />
              <ul v-if="placeholders.length > 0">
                <li
                  class="mb-1 font-medium"
                  v-for="(item, key) in placeholders"
                  :key="key"
                >
                  {{ item.value }}
                </li>
              </ul>
            </div>
          </div>
          <div
            class="
              w-full
              md:flex-1
              flex flex-col
              md:flex-row
              gap-4
              overflow-hidden
              h-screen
            "
          >
            <div
              class="
                w-full
                md:w-1/2
                overflow-x-hidden overflow-y-auto
                sb-farhan
              "
            >
              <div class="">
                <AppFormLabel class="ml-8 pl-1" text="HTML Editor" />
                <prism-editor
                  class="my-editor"
                  v-model="form.content"
                  :highlight="highlighter"
                  line-numbers
                />
              </div>
            </div>
            <div
              class="w-full
                md:w-1/2 py-5 px-5 overflow-y-auto"
            >
              <div
                class="w-full h-full  overflow-auto "
                style=" box-shadow: 5px 5px 20px 5px  #3134692c,-5px -5px 20px 5px #3134692c;"
              >
                <div v-html="form.content"></div>
              </div>
            </div>
          </div>
        </div>

        <button
          ref="submitButton"
          type="submit"
          class="hidden"
          @click="handleSubmit(onSave)"
        >
          Save
        </button>
      </ValidationObserver>
    </template>
  </EdgeStack>
</template>
<script>
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css'
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism-tomorrow.css' // import syntax highlighting styles

// import { useEndpoints } from '@/composables'
import { EdgeStack } from '@/components/modals'
// import { UploadFile } from '@/components/form'

import TemplateDetailsLanguageDropdown from './DetailsLanguageDropdown.vue'
import { useEndpoints } from '@/composables'
import { deepCompareObjects } from '@/utils'

const templateAddEditFormModel = () => ({
  language: '',
  content: '',
  fleet: '',
  template_group: '',
  template: null,
})
export default {
  name: 'TemplateAddEdit',

  components: {
    EdgeStack,
    TemplateDetailsLanguageDropdown,
    PrismEditor,
  },

  props: {
    esId: {
      type: String,
      default: 'template-add-edit',
    },
    busy: {
      type: Boolean,
      default: false,
    },
    primaryKey: {
      required: false,
    },
    formData: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      form: templateAddEditFormModel(),
      isReq: false,

      languages: [],
      fleets: [],
      placeholders: [],
    }
  },

  computed: {
    isEditing() {
      return !!this.formData
    },
    actionText() {
      return this.isEditing ? 'Update' : 'Create'
    },
  },

  async created() {
    await this.fetchLanguages()
    await this.fetchFleets()
    await this.fetchPlaceholders()
  },

  watch: {
    // sync props.formData with $data.from
    formData: {
      deep: true,
      immediate: true,
      handler(data) {
        console.log('formData', data)

        if (data) {
          this.form = { ...data }
        } else {
          this.form = templateAddEditFormModel()
        }
      },
    },

    // notify form is dirty & user should confirm before exiting
    form: {
      deep: true,
      immediate: false,
      handler(updatedFormData) {
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        // todo: should confirm before leaving
        this.$emit('dirty', { step: 1, data: this.form })
      },
    },
  },

  methods: {
    highlighter(code) {
      return highlight(code, languages.js)
    },
    async fetchLanguages() {
      await this.$http
        .get('/dashboard/languages/?dropdown=true')
        .then(({ data }) => {
          this.languages = data?.data.map(function(language) {
            let code = language.code2 ? language.code2 : language.code3
            if (code) code = code.toLowerCase()
            return {
              value: language.id,
              text: language.name,
              code,
            }
          })
        })
        .catch((err) => {
          console.log('fetchLanguagesErr', err, err.response)
        })
    },

    async fetchPlaceholders() {
      await this.$http
        .get('/dashboard/email-template-placeholders/?dropdown=true')
        .then(({ data }) => {
          this.placeholders = data?.data.map(function(placeholder) {
            return {
              value: placeholder.key,
              text: placeholder.key,
            }
          })
        })
        .catch((err) => {
          console.log('fetchPlaceholdersErr', err, err.response)
        })
    },

    async fetchFleets() {
      await this.$http
        .get('/dashboard/fleets/?dropdown=true')
        .then(({ data }) => {
          this.fleets = data?.data.map((fleet) => ({
            value: fleet.id,
            text: fleet.name,
          }))
        })
        .catch((err) => {
          console.log('fetchFleetsErr', err, err.response)
        })
    },

    success(data) {
      const verb = this.isEditing ? 'updated' : 'created'
      this.$emit('save', data)

      this.$edgeStack.close(this.esId)
      this.$edgeStack.emitter.on(
        this.$edgeStack.getEventName('closed', this.esId),
        () => {
          this.form = templateAddEditFormModel()
        }
      )

      this.$notify(
        {
          group: 'bottomLeft',
          type: 'success',
          title: `template has been ${verb}`,
          text: `The template has been successfully ${verb}`,
        },
        5000
      )
    },

    showError(err) {
      console.log('onSaveErr', err, err.response)
      this.$notify(
        {
          group: 'bottomLeft',
          type: 'error',
          title: `Error occured [${err?.response?.status}]`,
          text:
            err.response?.data?.detail ??
            `Failed to create template, please try agian.`,
        },
        5000
      )
    },

    async onSave() {
      if (this.busy) return

      this.isReq = true
      const formData = new FormData()
      formData.append('language', this.form.language)
      formData.append('fleet', this.form.fleet)
      formData.append('content', this.form.content)
      formData.append(
        'template',
        this.form.template ? this.form.template.id : ''
      )

      if (this.isEditing) {
        this.$http
          .patch(useEndpoints.mail.detail.update(this.primaryKey), formData)
          .then(({ data }) => {
            this.success(data)
          })
          .catch((err) => {
            this.showError(err)
          })
          .finally(() => (this.isReq = false))
      } else {
        this.$http
          .post(useEndpoints.mail.detail.create(), formData)
          .then(({ data }) => {
            this.success(data)
          })
          .catch((err) => {
            this.showError(err)
          })
          .finally(() => (this.isReq = false))
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.panel-title {
  font-size: 22px;
  font-weight: 500;
  color: #2e2e39;
}
.height-540px {
  height: 540px;
}
.overflow-y-scroll > div {
  height: 100%;
}
</style>
